.body {
  overflow: hidden;
}

.back {
  width: 100%;
  height: 40px;
  overflow: hidden;
}

.text {
  position: absolute;
  white-space: nowrap;
  animation: moveText 15s linear infinite;
  padding-top: 1px;
  font-size: 32px;
}

@keyframes moveText {
  from {
      left: 100%;
      /* テキストを画面外からスタートさせます */
  }

  to {
      left: -50%;
      /* テキストを画面外に移動させます */
  }
}